/*@import url('src/asset/font/font.scss');*/
/*@import url('../src/asset/font/font.scss');*/
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");

* {
  font-family: Pretendard,-apple-system,BlinkMacSystemFont,system-ui,Roboto,Helvetica Neue,Segoe UI,Apple SD Gothic Neo,Noto Sans KR,Malgun Gothic,sans-serif;
  font-display: swap;
  outline: 0 !important;
  outline: none !important;
  scroll-behavior: smooth !important;
  -webkit-user-select: none;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  } 
}

*:not([data-rsbs-overlay]) {
  /*
  -webkit-transition: all .1s ease;
  -moz-transition: all .1s ease;
  -o-transition: all .1s ease;
  transition: all .1s ease;
  */
}
  
:root {
  --vh: 100%;
}
  
html { 
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-content-zooming: none; 
  -ms-touch-action: pan-x pan-y; 
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
} 
  
body {
  width: 100vw;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}
  
#root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
  
a,
button,
input,
textarea {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

button {
  border: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
ul,
pre,
button {
  padding: 0;
  margin: 0; 
}

ul {
  list-style: none;
}

em {
  font-style: normal;
}
picture {
  width: 100%;
  height: 100%;
}





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import '../config/_colors';
@import '../config/variables';

.btnArea {
    @include flexContent;
    flex-direction: row;
    justify-content: flex-start;
    gap: .5rem;

    button {
        @include radius;
        padding: .5rem;
        background-color: $gray-0;
        font-weight: 500;
        font-size: 1rem;
        color: $white;
    }

    @include mobile { @include hide; }
}

.mypageArea {
    @include flexContent;
    flex-direction: column;
    @include desktop {
        gap: 2.5rem;
        margin-top: 2rem;
    }

    > * {
        width: calc(100% - (1.25rem * 2));
        padding: 1.25rem;
        background-color: $gray-0;
        @include desktop {
            @include radius;
        }
    }

    /* 파트너정보 */
    .infoContainer {
        position: relative;
        > button {
            @include flexRowContent;
            gap: .25rem;
            position: absolute;
            top: -2rem;
            right: 0;
            font-size: 1rem;
            color: $primary;
        }

        .infoArea {
            @include gridContent;
            @include desktop {
                grid-template-columns: 7.5rem 1fr;
                column-gap: 2.5rem;
                align-items: flex-start;
                width: calc(100% - (2.5rem * 2));
                padding: 2.5rem;
    
                div.imgArea {
                    width: 7.5rem;
                    height: 7.5rem;
                }
            }
            @include mobile {
                grid-template-columns: 3.75rem 1fr;
                align-items: flex-start;
                column-gap: 1.25rem;
                width: 100%;
                padding: 0 .25rem;
    
                div.imgArea {
                    width: 3.75rem;
                    height: 3.75rem;
                }
            }
            .basicInfo {
                @include flexRowContent;
                justify-content: flex-start;
                gap: .5rem;
                flex-wrap: wrap;
            }
            div {
                @include flexContent;
                flex-direction: column;
                align-items: flex-start;
                
                > div {
                    @include gridContent;
                    column-gap: 1.25rem;
                    width: 100%;
    
                    label {
                        @include lineClamp;
                        color: $white;
                    }
    
                    span {
                        font-weight: 500;
                    }
                }
    
                pre {
                    font-weight: 500;
                    white-space: pre-wrap;
                }

                > em {
                    border-radius: .125rem;
                    padding: .12rem .25rem;
                    background: rgba(216, 155, 0, .2);             
                    font-weight: 500;
                    font-size: .75rem;
                    color: $primary;
                }
                b {
                    border-radius: .125rem;
                    padding: .12rem .25rem;
                    background: $gray-1;
                    font-weight: 500;
                    font-size: .75rem;
                    color: $gray-25;
                }

                @include desktop {
                    gap: .5rem;
                    > div { 
                        grid-template-columns: auto 1fr; 
                        label {
                            font-weight: 600;
                            font-size: 1.25rem;
                            button { @include hide; }
                        }
                    }
                    span {
                        font-size: .875rem;
                        color: $gray-3;
                    }
                    pre {
                        line-height: 1.25rem;
                        font-size: .875rem;
                        color: $gray-25;
                    }
                }
    
                @include mobile {
                    gap: .75rem;
                    > div { 
                        grid-template-columns: 1fr auto; 
                        label {
                            @include gridContent;
                            grid-template-columns: 1fr 1rem;
                            column-gap: .5rem;
                            font-weight: 500;
                            font-size: 1rem;
                            em {
                                @include lineClamp;
                            }
                            button { 
                                @include show;
                                font-size: 1rem;
                                color: $primary;
                            }
                        }
                        span {
                            font-size: .75rem;
                            color: $gray-25;
                        }
                    }
                    pre {
                        line-height: 1.125rem;
                        font-size: .75rem;
                        color: $gray-2;
                    }
                }
            }
        }
    }

    /* 취급차량리스트 */
    .vehicleListArea {
        width: 100%;
        padding: 0;
        @include desktop {
            background: none;
        }

        ul {
            @include flexContent;
            flex-direction: column;
            width: 100%;

            @include desktop {
                gap: .75rem;
            }

            li {
                @include gridContent;
                column-gap: 2.25rem;
                background: $gray-0;
                width: calc(100% - (1.25rem * 2));
                
                label {
                    font-weight: 500;
                    font-size: 1rem;
                    color: $white;
                }

                div {
                    font-weight: 500;
                    font-size: .875rem;
                    color: $gray-25;
                    @include lineClamp;
                }

                button {
                    @include flexContent;
                }

                @include desktop {
                    grid-template-columns: auto 1fr auto;
                    padding: 1.25rem;
                }

                @include mobile {
                    grid-template-columns: 1fr auto;
                    padding: 1rem 1.25rem;

                    &:not(:first-child) {
                        border-top: 1px solid $gray-1;
                    }

                    label {
                        font-size: .875rem;
                    }

                    div { @include hide; }
                }
            }
        }
    }

    /* 공지사항리스트 */
    .noticeListArea {
        width: 100%;
        padding: 0;
        @include desktop {
            background: none;
        }

        ul {
            @include flexContent;
            flex-direction: column;
            gap: .75rem;
            width: 100%;

            li {
                @include flexContent;
                flex-direction: column;
                align-items: flex-start;
                background: $gray-0;
                width: calc(100% - (1.25rem * 2));
                padding: 1.25rem;

                div {
                    @include gridContent;
                    grid-template-columns: 1fr auto;
                    column-gap: .75rem;
                    width: 100%;

                    label {
                        font-weight: 500;
                        color: $white;
                    }

                    button {
                        color: $primary;
                    }
                }

                pre {
                    color: $gray-2;
                    @include lineClamp4;
                    white-space: pre-line;
                }

                @include desktop {
                    gap: .5rem;
                    @include radius;

                    label {
                        font-size: 1rem;
                    }

                    button {
                        font-size: 1.5rem;
                    }

                    pre {
                        line-height: 1.25rem;
                        font-weight: 500;
                        font-size: .875rem;
                    }
                }

                
                @include mobile {
                    gap: .25rem;

                    &:not(:first-child) {
                        border-top: 1px solid $gray-1;
                    }

                    label {
                        font-size: .875rem;
                    }

                    button {
                        font-size: 1rem;
                    }

                    pre {
                        line-height: 1rem;
                        font-weight: 400;
                        font-size: .75rem;
                    }
                }
            }
        }
    }

    /* 링크버튼 */
    .btnLinkedArea {
        @include gridContent;
        grid-template-columns: 1fr auto;
        @include cursor;
        font-size: 1.5rem;
        color: $white;

        label {
            font-weight: 500;
            font-size: 1rem;
        }

        &.highlight {
            * {
                color: $primary;
            }
        }

        &.mobile {
            @include desktop { @include hide; }
        }
    }
}

.dateFormat {
    @include gridContent;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    
    @include desktop {column-gap: .5rem;}
    @include mobile {column-gap: .25rem;}

    > div {
        border: 1px solid $gray-3;
        background: $white;
        color: $gray-1;

        @include desktop {
            width: calc(100% - 2px);
            padding: 1.25rem 0;
            border-radius: .25rem;
        }
        @include mobile {
            width: calc(100% - 2px);
            padding: .88rem 0;
            border-radius: .5rem;
        }
    }
}

/* 회원정보수정 */
.infoUpdateContainer {
    @include gridContent;
    grid-template-columns: 7.5rem 1fr;
    column-gap: 2.5rem;
    align-items: flex-start;

    > {
        width: 100%;        
    }

    .imgArea {
        width: 7.5rem;
        height: 7.5rem;
        margin-top: 4rem;
    }

    .infoUpdateArea {
        @include flexContent;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.5rem;
    
        > .btnArea {
            @include flexContent;
            justify-content: flex-end;
            width: 100%;
    
            button {
                font-weight: 500;
                font-size: 1rem;
                color: $primary;
                &:disabled {
                    color: $gray-2;
                }
            }
        }
    
        .selectField,
        .inputField {
            @include flexContent;
            flex-direction: column;
            align-items: flex-start;
            gap: .75rem;
            width: 100%;
    
            label {
                font-weight: 500;
                color: $white;
                @include desktop {font-size: .875rem;}
                @include mobile {font-size: .75rem;}
            }
        }
        .holidayArea {
            .inputField {
                flex-direction: row !important;
                align-items: center !important;
                justify-content: center !important;
                @include mobile {
                    width: 100%;
                    label {
                        font-size: .75rem;
                    }
                }
            }
        }
    
        .dateFormat {
            @include gridContent;
            grid-template-columns: 1fr auto 1fr;
            width: 100%;
            @include desktop {column-gap: .5rem;}
            @include mobile {column-gap: .25rem;}
            
            > div {
                border: 1px solid $gray-1;
                background: $gray-1;
                color: $white;
    
                @include desktop {
                    width: calc(100% - 2px);
                    padding: 1.25rem 0;
                    border-radius: .25rem;
                }
                @include mobile {
                    width: calc(100% - 2px);
                    padding: .88rem 0;
                    border-radius: .5rem;
                }
            }
    
            span {
                font-weight: 500; 
                font-size: .875rem;
                color: $white;
            }
        }
    
        .inputField {
            @include flexContent;
            flex-direction: column;
            align-items: flex-start;
            gap: .75rem;
            width: 100%;
    
            > div {
                position: relative;
                border: 1px solid $gray-1;
                background: $gray-1;
                color: $white;
        
                @include desktop {
                    width: calc(100% - (1.5rem * 2) - 2px);
                    padding: 1.25rem 1.5rem;
                    border-radius: .25rem;
                }
                @include mobile {
                    width: calc(100% - (1rem * 2) - 2px);
                    padding: .88rem 1rem;
                    border-radius: .5rem;
                }
    
                textarea,
                input {
                    width: 100%;
                    border: none;
                    background: none;
                    font-weight: 500;
                    font-size: 1rem;
                    color: $gray-3;
    
                    ::placeholder {
                        font-weight: 400;
                        color: $gray-3;
                    }   
                }
    
                textarea{
                    height: calc(8.75rem - (1.25rem * 2));
                }
    
                &:has(input:placeholder-shown) {
                    background: $gray-4;
                    border: 1px solid $gray-4;
                }
            }
    
            em {
                width: 100%;
                text-align: right;
                font-weight: 500;
                font-size: .75rem;
                color: $gray_2;
            }
        }
    }
}
/* 회원정보수정 bottomsheet */
.bottomImg {
    @include flexContent;
    width: 100%;
    margin-top: 1rem;
    > div {
        width: 3.75rem;
        height: 3.75rem;
    }
}

.comnContainer {
    @include flexColumnContent;
    > * {
        box-sizing: border-box;
        width: 100%;
        @include desktop {
            &:not(:first-child) {
                @include radius;
            }
        }
        @include mobile {
            background-color: $gray-0;
        }
    }
    > div {
        &:not(:first-child) {
            @include desktop {
                padding: 1.25rem;
            }
            @include mobile {
                padding: 0.5rem 1.25rem;
            }
        }
    }
}

/* START 영업시간리스트 */
.bizDateContainer {
    @include flexColumnContent;
    align-items: flex-end;
    width: 100%;
    padding: 0 !important;
    background: transparent;
    > button {
        font-size: 1.5rem;
        color: $primary;
    }
    .bizDateArea {
        @include flexColumnContent;
        gap: 4px;
        align-items: flex-start;
        box-sizing: border-box;
        background-color: $gray-0;
        > div {
            @include flexRowContent;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 8px;
            line-height: 150%;
            font-weight: 500;
            color: $white;
            @include desktop {
                font-size: 1rem;
                > div {
                    line-height: 150%;
                }
            }
            @include mobile {
                font-size: .875rem;
                > div {
                    line-height: 120%;
                }
            }
            &.selected {
                font-weight: 600 !important;
                color: $primary;
            }
        }
    }
}
/* END 영업시간리스트 */
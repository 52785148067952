@import '../config/_colors';
@import '../config/variables';

h5 {
    font-weight: 600;
    font-size: 1.125rem;
}
/* START 유형선택버튼공통 */
.btnArea {
    @include gridContent;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
    width: 100%;
    button {
        background-color: $white;
        border: 1px solid $gray-4;
        @include radius;
        line-height: 150%;
        font-weight: 500;
        color: $gray-2;
        @include desktop {
            padding: 1.25rem 1rem;
            font-size: 1rem;
        }
        @include mobile {
            height: 100%;
            padding: 1rem .88rem;
            font-size: .875rem;
        }
        &.selected {
            background-color: $primary-rgba;
            border: 1px solid $primary;
            color: $primary-outline;
        }
    }
}
/* END 유형선택버튼공통 */
/* 요일 추가 및 토/일분리 버튼 */
.btnDayAdd {
    width: 100%;
    background-color: rgba(255, 212, 0, .1);
    @include radius;
    font-weight: 600;
    color: $primary-outline;
    @include desktop {
        padding: 1rem;
        font-size: 1.125rem;
    }
    @include mobile {
        padding: .88rem;
        font-size: 1rem;
    }
}

/* START 공휴일 영업유무 */
.holidayArea {
    @include flexColumnContent;
    gap: .75rem;
    justify-content: flex-start;
    align-items: flex-start;
    div {
        grid-template-columns: repeat(2, 1fr);
    }
}
/* END 공휴일 영업유무 */
/* START 요일별상이-요일선택 */
.dateListArea {
    @include flexContent;
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-start;
    width: 100%;
    button {
        background-color: $gray-4;
        border: 1px solid $gray-3rgba;
        border-radius: 30px;
        padding: .75rem;
        font-weight: 500;
        font-size: .875rem;
        color: $gray-2;
        &.selected {
            background-color: $primary-rgba;
            border: 1px solid $primary;
        }
    }
}
/* END 요일별상이-요일선택 */
/* START 영업시간타입선택버튼 */
.daysArea {
    @include flexColumnContent;
    gap: .75rem;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5rem;
    .daysBtnArea {
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 1.25rem;
        border-bottom: 1px solid $gray-3;
    }
}
/* END 영업시간타입선택버튼 */
/* START 영업시간 기본컴포넌트(요일 날짜, 휴게시간 등 공통) */
.daysContainer {
    @include flexColumnContent;
    gap: 1.75rem;
    justify-content: flex-start;
    width: 100%;
    > * {
        @include flexColumnContent;
        gap: .75rem;
        justify-content: flex-start;
        width: 100%;
    }
    .dayArea {
        @include flexColumnContent;
        gap: .75rem;
        width: 100%;
        > * {
            width: 100%;
        }
        .timeContainer {
            @include flexColumnContent;
            justify-content: flex-start;
            align-items: flex-start;
            @include desktop {
                gap: .75rem;
            }
            @include mobile {
                gap: .38rem;
            }
            > label {
                font-weight: 500;
                color: $gray-1;
                @include desktop {
                    font-size: .875rem;
                }
                @include mobile {
                    font-size: .75rem;
                }
            }
            .timeArea {
                @include gridContent;
                grid-template-columns: 1fr auto 1fr;
                gap: .5rem;
                width: 100%;
                > div {
                    border: 1px solid $gray-3;
                    @include radius;
                }
            }
        }
        > h2 {
            @include flexContent;
            flex-direction: row;
            justify-content: flex-start;
            gap: .25rem;
            > * {
                @include desktop {
                    font-size: .875rem;
                }
                @include mobile {
                    font-size: .75rem;
                }
            }
            label {
                font-weight: 500;
                color: $gray-1;
            }
            button {
                font-weight: 600;
                color: $primary-outline;
            }
        }
        /* START 휴게시간 */
        .timeArea {
            position: relative;
            .btnBreakTime {
                position: absolute;
                top: -.75rem;
                right: calc(1.5rem * .5);
                width: 1.5rem;
                height: 1.5rem;
                background-color: $white;
                border: 1px solid $gray-3;
                border-radius: 50%;
                color: $gray-3;
            }
        }
        /* END 휴게시간 */
    }
}
/* END 영업시간 기본컴포넌트(요일 날짜, 휴게시간 등 공통) */
$white: #FFF;
$black: #000;
$gray-0: #191A1A;
$gray-1: #373737;
$gray-2: #737373;
$gray-3: #C9C9C9;
$gray-4: #F3F3F3;
$gray-5: #FBFBFC;
$gray-6: #93969B;
$gray-25 : #A1A1A1;
$gray-35 : #D9D9D9;
$primary: #FFD400;
$primary-outline: #D89B00;
$primary-bg: #FFF7CB;
$point-blue: #35B6FF;
$kakao: #FEE500;
$naver: #00CD4C;
$correct : #00E432;
$error: #FF5454;
$gray-3rgba : rgba(201, 201, 201, .4);
$primary-rgba : rgba(255, 212, 0, .05);
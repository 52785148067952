@import '../config/_colors';
@import '../config/variables';

.searchWordArea {
    @include desktop {
        margin-top: 2rem;
    }
    @include mobile {
        padding: 1.19rem 1.25rem;
        background: $gray-0;
        border-bottom: 1px solid $gray-1;
    }

    > div {
        @include gridContent;
        grid-template-columns: auto 1fr;
        column-gap: 1.25rem;
        background: $white;
        @include radius;
        width: calc(100% - (1.25rem * 2));
        color: $gray-1;
        @include desktop {
            padding: 1rem 1.25rem;
            font-size: 1.5rem;
        }
        @include mobile {
            padding: .75rem;
            font-size: 1rem;
        }

        input {
            width: 100%;
            border: none;
            background: none;
            font-weight: 500;
            color: $gray-1;
            @include desktop {
                font-size: 1rem; 
            }
            @include mobile {
                font-size: .875rem; 
            }

            &::placeholder {
                color: $gray_3;
            }
        }
    }
}

.membersContainer {
    ul {
        @include flexContent;
        flex-direction: column;
        gap: .75rem;

        li {
            width: 100%;
            > div:first-child {
                @include gridContent;
                width: calc(100% - (1.25rem * 2));
                background: $gray-0;
                padding: 1.12rem 1.25rem;
                @include desktop {
                    @include radius;
                    grid-template-columns: auto auto 1fr auto;
                }
                @include mobile {
                    grid-template-columns: auto auto 1fr auto;
                }
    
                > :not(label, i) {
                    border-left: 1px solid $gray-1;
                }
    
                > :not(i) {
                    @include desktop { padding: 0 1.25rem; }
                    @include mobile { padding: 0 .25rem; }
                }
    
                > * {
                    @include lineClamp;
                }
    
                label,
                div,
                span {
                    @include desktop {
                        font-size: 1rem;
                    }
                    @include mobile {
                        font-size: .875rem;
                    }
                }
    
                label {
                    font-weight: 500;
                    color: $gray-3;
                }
    
                div {
                    text-align: center;
                    font-weight: 500;
                    color: $gray-2;
                }
    
                span {
                    text-align: right;
                    font-weight: 500;
                    font-size: .875rem;
                    color: $white;
    
                    em {
                        color: $gray_2;
                        @include mobile {
                            display: none;
                        }
                    }
                }
    
                i {
                    color: $white;
                    @include cursor;
                    @include desktop {
                        font-size: 1.5rem;
                    }
                    @include mobile {
                        font-size: 1rem;
                    }
                }
            }

            .contentArea {
                @include flexContent;
                flex-direction: column;
                gap: 1.25rem;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: .06rem;
                background: $gray-0;
                @include radius;
                padding: 2.5rem;
                max-height: 300px;
                overflow-y: auto;

                div {
                    @include gridContent;
                    grid-template-columns: repeat(3, auto);
                    font-weight: 500; 
                    @include desktop {
                        gap: 1.25rem;
                        font-size: .875rem; 
                    }
                    @include mobile {
                        gap: .625rem;
                        font-size: .75rem; 
                    }

                    > :not(:first-child) {
                        border-left: 1px solid $gray-2;
                        @include desktop {
                            padding-left: 1.25rem;
                        }
                        @include mobile {
                            padding-left: .625rem;
                        }
                    }
                    label {
                        color: $primary;
                    }
                    span {
                        color: $gray-2;
                        em {
                            margin-right: 8px;
                            color: $white;
                        }
                    }
                }

                article {
                    @include flexContent;
                    flex-direction: column;
                    gap: .75rem;
                    align-items: flex-start;

                    label {
                        font-weight: 500;
                        color: $white;
                        @include desktop {
                            font-size: 1rem; 
                        }
                        @include mobile {
                            font-size: .875rem;
                        }
                    }

                    pre {
                        line-height: 1.25rem;
                        font-weight: 500;
                        color: $gray-25;
                        white-space: pre-wrap;
                        @include desktop {
                            font-size: .875rem; 
                        }
                        @include mobile {
                            font-size: .75rem; 
                        }
                    }
                }
            }
        }
    }
}
@import '../config/_colors';
@import '../config/variables';

.statusBar {
    @include gridContent;
    grid-template-columns: 1.5rem 1fr 1.5rem;
    @include desktop {margin-bottom: 1.25rem;}
    @include mobile {margin-bottom: 2.5rem;}

    .prevBtnArea {
        button {
            font-size: 1.5rem;
            color: $gray-0;
        }
    }
}
@import '../config/colors';
@import '../config/variables';
/* 최초로딩 */
.pending {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: .5em solid rgba(255, 255, 255, 0.2);
    border-right: .5em solid rgba(255, 255, 255, 0.2);
    border-bottom: .5em solid rgba(255, 255, 255, 0.2);
    border-left: .5em solid $white;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: isPending 1.1s infinite linear;
    animation: isPending 1.1s infinite linear;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    &::after {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
    }
}
@-webkit-keyframes isPending {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes isPending {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}
/* 버튼submitLoader */
.loaderArea {
    @include flexContent;
    flex-direction: row;
    gap: 1.25rem;
    &.initLoad {
        flex-direction: column;
        padding: 4rem 0;
    }
    .loader {
        position: relative;
        width: 2rem;
        margin-top: -.5rem;
        .dot {
            position: absolute;
            width: .5rem;
            height: .5rem;
            border-radius: 0.5em;
            background: $gray-2;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            &.dot1, 
            &.dot2 { left: 0; }
            &.dot3 { left: .75rem; }
            &.dot4 { left: 1.5rem; }
        }
    }

    label {
        @include desktop {
            font-size: .875rem;
        }
        @include mobile {
            font-size: .75rem;
        }
        color: $gray-2;
    }
}
  
@keyframes reveal {
    from { transform: scale(0.001); }
    to { transform: scale(1); }
}
  
@keyframes slide { to { transform: translateX(.75rem) } }
  
.dot1 { animation-name: reveal; }
.dot2, .dot3 { animation-name: slide; }
.dot4 {
    animation-name: reveal;
    animation-direction: reverse; /* thx @HugoGiraudel */
}

/* 무한로딩 */
.infiniteScroll {
    @include flexContent;
    width: 100%;
    padding: 1.25rem 0 ;
    background: transparent; 
    .dot {
        background: $gray-35 !important;
    }
}
@import './_colors';

$desktop : 1024px;
$mobile: 767px;

/* 반응형 */
@mixin mobile{
    @media (max-width: $mobile){
        @content;
    }
}

@mixin desktop{
    @media (min-width: $mobile){
        @content;
    }
}

@mixin cursor {
    cursor: pointer;
}

@mixin square($size){
    $calculated: 32px * $size;
    width: $calculated;
    height: $calculated;
}

@mixin container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@mixin wrap {
    width: calc(100% - 40px);
    max-width: 900px;
    padding: 0 20px;
    margin: 0 auto;
}

@mixin flickPanel {
    position: relative;
    flex-grow: 1;
    width: 100%;
	height: 100%;
	overflow-y: auto;
    overflow-x: hidden;
}

@mixin fixedPanel {
	width: 100%;
}

@mixin fixedContent {
    width: 100%;
}

@mixin flexContent {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin flexRowContent {
    @include flexContent;
    flex-direction: row;
}
@mixin flexColumnContent {
    @include flexContent;
    flex-direction: column;
}
@mixin flexBetweenContent {
    @include flexContent;
    justify-content: space-between;
}

@mixin gridContent {
    display: grid;
    align-items: center;

    > * {
        min-width: 0;
    }
}

@mixin scrollXContent {
    @include wrap;
    width: calc(100% - 1.25rem);
    padding: 0 1.25rem 0 0;
    margin-top: .75rem;
    overflow-x: auto;
}

/* title */
@mixin Title {
    font-weight: 700;
    font-size: 1.5rem;
    color: $gray-0;
}

@mixin title {
    font-weight: 600;
    font-size: 1rem;
    color: $gray-0;
}

@mixin SubTitle {
    font-weight: 500;
    font-size: .875rem;
    color: $gray-2;
}

/* Button */
@mixin buttonDisabled {
    color: $white !important;
    background: $gray-3 !important;
    border: 1px solid $gray-3 !important;
}

@mixin axiosButton {
    border-radius: .5rem;
}

@mixin submitButtonArea {
    @include flexContent();
    flex-direction: column;
    gap: 1rem;
    padding: 1.25rem 0;
}

@mixin submitButton {
    @include flexContent();
    @include axiosButton();
    width: 100%;
    font-weight: 600;
    box-sizing: "border-content";
    @include desktop {
        padding: 1.25rem 2.88rem;
        font-size: 1.125rem;
    }
    @include mobile {
        padding: .88rem 2.88rem;
        font-size: 1rem;
    }
}

@mixin submitCurrButton {
    background: $primary;
    border: 1px solid $primary;
    color: $gray-0;
}

@mixin submitSecButton {
    background: $white;
    border: 1px solid $gray-3;
    color: $gray-0;
}

@mixin submitErrButton {
    background: $error;
    border: 1px solid $error;
    color: $white;
}

/* 체크박스공통 */
@mixin checkbox {
    position: relative;
    display: inline-block;
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #C9C9C9 !important;
    border-radius: 50% !important;
    background: $white !important;
    overflow: hidden !important;
    cursor: pointer !important;
}

@mixin checkboxChecked {
    position: relative;
    background: $gray-1 !important;
    border: none !important;
    color: $white !important;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin checkboxLabel {
    margin: 0 !important;
    font-weight: 500;
    font-size: .75rem;
    color: $gray-1;
}

/* 말줄임 */
@mixin lineClamp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin lineClamp2 {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin lineClamp3 {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin lineClamp4 {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* 데이터없음 */
@mixin isEmpty {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .75rem;
    display: inline-flex;    
    width: 100% !important;
    min-height: 13.125rem;
    text-align: center !important;;
    font-size: 1.625rem !important;;
    color: $gray-35 !important;

    span {
        line-height: 1.25rem !important;
        font-weight: 400 !important;
        font-size: .875rem !important;
        color: $gray-25 !important;
    }
}

/* 검색필터 */
@mixin subFilter {
    background: $gray-5;
    border-top: 1px solid $gray-4;
    border-bottom: 1px solid $gray-4;
    padding: .75rem 0;
}

@mixin subFilterSort {
    @include gridContent;
    column-gap: 1.25rem;
}

/* border-radius */
@mixin radius {
    @include desktop { border-radius: .25rem; }

    @include mobile { border-radius: .5rem; }
}

@mixin show { display: ''; }
@mixin hide { display: none; }
@import '../config/_colors';
@import '../config/variables';

.titleBarArea {
    padding: .75rem 0;
    border-bottom: 1px solid $gray-2;
    padding-bottom: .75rem;
    border-bottom: 1px solid $gray-2;
    margin-bottom: 2.12rem;

    label {
        font-weight: 600;
        font-size: 1.25rem;
        color: $white;
    }

    @include mobile {
        display: none;
    }
}
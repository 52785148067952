@import '../config/_colors';
@import '../config/variables';

/* 상단통계 */
.sisDesktop {
    @include mobile {
        display: none;
    }
    .sisArea {
        @include gridContent;
        grid-template-columns: repeat(3, auto);
        column-gap: .5rem;   
        width: 100%;
        margin-bottom: .875rem;
    
        li {
            @include gridContent;
            grid-template-columns: 1fr auto;
            padding: 1.25rem;
            background: $gray-0;
            @include radius;
            font-weight: 500;

            label {
                font-size: .875rem;
                color: $gray-2;
            }
    
            em {
                font-size: 1rem;
                color: $primary;
            }
            
            b {
                font-size: 1rem;
                color: $white;
            }
        }
    }
}

.sisMobile {
    @include desktop {
        display: none;
    }

    .sisArea {
        @include flexContent;
        flex-direction: column;
        width: 100%;
        margin: .5rem 0;
        
        li {
            @include gridContent;
            grid-template-columns: 1fr auto;
            padding: 1.25rem;
            background: $gray-0;
            width: calc(100% - (1.25rem * 2));
            font-weight: 500; 
            font-size: .875rem;

            &:not(:last-child) {
                border-bottom: 1px solid $gray-1;
            }
            
            label {
                color: $gray-2;
            }
    
            em {
                color: $primary;
            }
            
            b {
                font-weight: inherit; 
                color: $white;
            }
        }
    }
}

.dashboardArea {
    @include flexContent;
    flex-direction: column;
    @include desktop {
        gap: 1.25rem;
        height: calc(100% - .1875rem);
    }

    @include mobile {
        gap: .5rem;
    }

    .gridArea {
        @include flexContent;
        flex-direction: row;
        align-items: flex-start;
        gap: 1.25rem;
        width: 100%;
        height: calc(50% - (1.25rem/2));
        
        .chartArea {
            @include flexColumnContent;
            gap: .5rem;
            padding: 1.25rem;
            background: $gray-0;
            > * {
                width: 100%;
            }
            @include desktop {
                width: calc(60% - (1.25rem * 2));
                height: calc(100% - (1.25rem * 2));
                @include radius;
            }
            @include mobile {
                width: calc(100% - (1.25rem * 2));
                height: 17.25rem;
            }
            
            
            label {
                @include gridContent;
                grid-template-columns: 1fr auto;
                font-weight: 500;
                font-size: 1rem;
                color: $white;

                b {
                    font-weight: 400;
                    font-size: .6875rem;
                    color: $gray-2;
                }
            }

            > div {
                width: 100%;
                height: 100%;
            }

            // path {
            //     stroke: $primary;
            // }
                        .dataListArea {
                            @include gridContent;
                            grid-template-columns: 1fr 1fr;
                            column-gap: 1rem;
                            row-gap: .5rem;
                            width: 100%;
            
                            li {
                                @include flexBetweenContent;
                                gap: .25rem;
            
                                >* {
                                    white-space: nowrap;
                                    font-size: .875rem;
                                }
            
                                div {
                                    width: 1rem;
                                    min-width: 1rem;
                                    height: 1rem;
                                    min-height: 1rem;
                                    // @include comnBorderRadius;
                                }
            
                                span {
                                    width: 100%;
                                    font-weight: 500;
                                    color: $gray-5;
                                }
            
                                label {
                                    font-weight: 700;
                                    color: $white;
                                }
                            }
                        }
        }
    
        .listArea {
            @include flexContent;
            flex-direction: column;
            justify-content: flex-start;
            gap: .75rem;
            width: 40%;
            height: calc(100% - .75rem);
            @include mobile {
                display: none;
            }

            label.title {
                @include flexContent;
                flex-direction: row;
                gap: .25rem;
                justify-content: flex-start;
                width: 100%;
                font-weight: 500;
                font-size: 1rem;
                color: $white;
            }

            > :not(label) {
                flex-grow: 1;
            }
    
            ul {
                @include flexContent;
                flex-direction: column;
                gap: .5rem;
                justify-content: flex-start;
                width: 100%;
                margin-top: .75rem;
                max-height: calc(100% - 1.25rem - .75rem);
                overflow-y: auto;

                li {
                    @include gridContent;
                    grid-template-columns: .75fr auto 1fr auto .75fr auto 3fr;
                    width: calc(100% - (1.25rem * 2));
                    padding: .75rem 1.25rem;
                    background: $gray-0;
                    @include radius;
                    font-weight: 500;
                    font-size: .875rem;
    
                    > * {
                        @include lineClamp;
                    }
    
                    label {
                        color: $gray-3;
                    }
    
                    hr {
                        display: inline-block;
                        width: .0625rem;
                        height: 100%;
                        margin: 0 1rem;
                        background: $gray-1;
                        border: none;
                    }
    
                    span {
                        color: $gray-2;
                    }
                }
            }
        }
    }
}

/* START 차트툴팁 */
.tooltipArea {
    @include flexColumnContent;
    gap: .12rem;
    padding: .5rem .75rem;
    border-radius: .25rem;
    background-color: $gray-1;
    > div {
        @include gridContent;
        grid-template-columns: 3rem 1fr;
        gap: .25rem;
        width: 100%;
        > * {
            font-size: .75rem !important;
        }   
        span {
            font-weight: 500;
            color: $gray-4;
        }
        label {
            text-align: right;
            font-weight: 700;
            color: $white;
        }
    }
}
/* END 차트툴팁 */
/* START 제목 */
h1 {
    font-weight: 600;
    font-size: 1.125rem;
    color: $white;
}
.titleArea {   
    @include flexBetweenContent;
    > div {
        @include flexRowContent;
        gap: .75rem;
        span {
            position: relative;
            padding-left: .5rem;
            font-weight: 500;
            font-size: .75rem;
            color: $white;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-50%);
                display: block;
                width: .25rem;
                height: .25rem;
                border-radius: 50%;
            }
            &:first-child {
                &::before {
                    background-color: $white;
                }
            }
            &.green {
                &::before {
                    background-color: $correct;
                }
            }
            &.yellow {
                &::before {
                    background-color: $primary;
                }
            }
        }
    }
}
/* END 제목 */
@import '../config/_colors';
@import '../config/variables';

.scheduleContainer {
    @include flexContent;
    flex-direction: row;
	justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
	overflow-x: hidden;

    ul {
        @include flexContent;
        flex-direction: column;
	    gap: .5rem;

        li {
            @include flexContent;
            width: 100%;            
            font-weight: 500;
            font-size: .875rem;
            color: $gray-3;

            &:first-child {
                height: 1.25rem !important;
            }
        }
    }

    .timeArea {
        li {
            color: $gray-3;
        }
    }
    > div {
        @include gridContent;
        @include desktop {
            grid-template-columns: repeat(auto-fit, minmax(8.125rem, 1fr));
            grid-auto-columns: minmax(8.125rem, 1fr);
        }
        @include mobile {
            grid-template-columns: repeat(auto-fit, minmax(11.125rem, 1fr));
            grid-auto-columns: minmax(11.125rem, 1fr);
        }
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: .5rem;
        grid-auto-flow: column;
        overflow-x: auto;
        flex-grow: 1;
        .scheduleArea {
            width: 100%;
            
            li {
                @include radius;
                overflow: hidden;
                @include cursor;
                background: $gray-0;

                i {
                    font-size: 1.5rem; 
                }

                &.mechanic {
                    @include gridContent;
                    grid-template-columns: 1fr auto;
                    background: transparent;
                    color: $white;

                    label {
                        font-weight: 500;
                        font-size: .875rem;
                        @include cursor;
                    }
                    span {border-radius: 1.125rem;
                    }
                    div {
                        @include flexContent;
                        flex-direction: row;
                        gap: 4px;
                        button { @include flexContent;
                            height: 100%;
                            padding: .05rem .75rem;
                            @include radius;
                            font-weight: 500;
                            font-size: .75rem;
                            box-sizing: content-box;
                            &.btnEnable {
                                border: 1px solid $primary;
                                color: $primary;
                             }
                             &.btnDisable {
                                border: 1px solid $gray-3;
                                color: $gray-3;
                            }
                        }
                    }
                }

                &.status0 {
                    background: $black;
                }

                &.status1 {
                    background: $gray-0;
                }

                &.status2 {
                    @include flexContent;
                    gap: .5rem;
                    background: $primary;
                    font-weight: 500;
                    color: $gray-0;
                }

                &.status3,
                &.status4,
                &.status5, 
                &.status6 {
                    @include gridContent;
                    grid-template-columns: auto 1fr auto;
                    column-gap: .5rem;
                    background: $gray-0;

                    b {
                        display: inline-block;
                        width: .25rem;
                        height: 100%;
                    }
                    i {
                        margin-right: .75rem;
                    }
                }

                &.status3 {
                    color: $white;
                    b {background: $white;}
                }
                &.status4 {
                    color: $error;
                    b {background: $error;}
                }
                &.status5 {
                    color: $correct;
                    b {background: $correct;}
                }
                &.status6 {
                    color: $point-blue;
                    b {background: $point-blue;}
                }
            }
        }
    }

    @include desktop {
        gap: 1.34rem;

        li {
            height: 3rem;
        }

        .timeArea {
            li {
                font-weight: 500;
                font-size: .875rem;
            }
        }

        .scheduleArea {
            li {
                &.status2 {
                    font-size: 1rem;
                }
                i {
                    font-size: 1.5rem; 
                }
            }
        }
    }
    @include mobile {
        gap: .75rem;
        padding: 0 1.25rem;

        li {
            height: 2.75rem;
        }

        .timeArea {
            li {
                font-weight: 400;
                font-size: .6875rem;
            }
        }

        .scheduleArea {
            li {
                &.status2 {
                    font-size: .875rem;
                }
                i {
                    font-size: 1rem; 
                }
            }
        }
    }
}

/* 상태안내 */
.statusArea {
    @include mobile {
        display: none;
    }

    @include flexContent;
    flex-direction: row;
    gap: .75rem;

    span {
        @include flexContent;
        flex-direction: row;
        gap: .25rem;

        b {
            display: inline-block;
            width: .25rem;
            height: .25rem;
            border-radius: 50%;
        }

        &.state1 {
            b{background: $primary;}
        }
        &.state2 {
            b{background: $white;}
        }
        &.state3 {
            b{background: $error;}
        }
        &.state4 {
            b{background: $correct;}
        }
        &.state5 {
            b{background: $point-blue;}
        }

        label {
            font-weight: 500;
            font-size: .75rem;
            color: $gray-35;
        }
    }
}

/* 일자별상태리스트 */
.daysArea {
    width: 100%;
    @include desktop { }
    @include mobile { /*padding: 1.25rem 0;*/ }

    ul {
        @include flexContent;
        flex-direction: row;
        gap: 1.25rem;
        justify-content: flex-start;
        overflow-x: auto;
        @include desktop {
            width: 100%;
        }
        @include mobile {
            width: calc(100% - (1.25rem * 2));
            padding: 0 1.25rem;
        }

        li {
            background: $gray-0;
            @include radius;
            padding: .5rem;
            @include flexContent;
            flex-direction: row;
            gap: .875rem;

            label {
                @include flexContent;
                line-height: 1.25rem;
                text-align: center;
                font-weight: 500;
                font-size: .75rem;
                color: $gray-2;
            }

            div {
                @include flexContent;
                flex-direction: column;
                gap: .25rem;
                align-items: flex-start;

                span {
                    @include flexContent;
                    flex-direction: row;
                    gap: .25rem;
                    font-weight: 400; 
                    font-size: .6875rem;
                    color: $white;
        
                    &.cnt1 {
                        b {
                            background: $gray-1;
                        }
                    }
                    &.cnt2 {
                        b {
                            background: $primary;
                        }
                    }

                    b {
                        display: inline-block;
                        width: .25rem;
                        height: .25rem;
                        border-radius: 50%;
                    }
                }
            }

            i {
                font-size: 1.5rem;
                color: $white;
            }
        }
    }
}

/* START 스케줄리스트없을때 */
.isEmpty {
    justify-content: center !important;
}
/* END 스케줄리스트없을때 */
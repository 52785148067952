@import '../config/colors';
@import '../config/variables';

.fileArea {
    @include flexContent;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .listContainer {
        @include flexContent;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        li {
            @include gridContent;
            grid-template-columns: auto 2.5rem 1fr;
            column-gap: .75rem;
            width: 100%;
            height: 3.849rem;
            border: 1px solid $gray-3;
            border-radius: .5rem;
            padding: .38rem 1.25rem;
            box-sizing: border-box;
            i {
                font-size: 1rem;
                color: $gray-1;
            }
            .imgArea {
                @include flexContent;
                width: 2.5rem;
                height: 2.5rem;
                background: $gray-4;
                border-radius: .25rem;
                overflow: hidden;
                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
            label {
                text-align: right;
                font-weight: 400;
                font-size: .875rem;
                color: $gray-1;
                @include lineClamp;
            }
        }
    }
    .btnArea {
        width: 100%;
        height: 3.849rem;
        button {
            @include flexContent;
            flex-direction: row;
            gap: .75rem;
            width: calc(100% - 2px);
            border: 1px dashed $gray-3;
            color: $gray-2;
            @include desktop {
                padding: 1.19rem 0;
                border-radius: .25rem;
                font-weight: 600;
                font-size: 1rem;
            }
            @include mobile {
                padding: .88rem 0;
                border-radius: .5rem;
                font-weight: 500;
                font-size: .875rem;
            }
            input[type=file] {
                display: none;
            }
        }
    }
}
@import '../config/_colors';
@import '../config/variables';

.navArea {
    @include flexColumnContent;
    background: $gray-0;
    justify-content: flex-start;
    @include desktop {
        height: 100%;
        padding: 3.5rem 2rem;    
        box-sizing: border-box;
    }
    @include mobile {
        width: calc(100% - (1.25rem * 2));
        padding: 0 1.25rem;
    }
    > * {
        width: 100%;
    }
    .header {
        @include gridContent;
        grid-template-columns: 2rem 1fr auto;
        column-gap: .25rem;
        div {
            width: 2rem;
            height: 2rem;
            // background-color: $primary;
            background-image: url('../../images/logo/logo-basic_c_37.png');
            background-size: 80% auto;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: .5rem;
        }
        label {
            @include lineClamp;
            font-weight: 600;
            font-size: .875rem;
            color: $white;
        }

        button {
            @include flexContent;
            flex-direction: row;
            gap: .25rem;
            font-weight: 500;
            font-size: .75rem;
            color: $gray-25;

            
        }
        @include desktop {
            margin-bottom: 2.5rem;
        }
        @include mobile { 
            padding: 1.25rem;
            button {@include hide;} }
    }
    nav {
        @include flexContent;
        width: 100%;
        border-bottom: 1px solid $gray-0;
        @include desktop {
            align-items: flex-start;
            height: 100%;
        }
        @include mobile {
            overflow-x: auto;
        }
        ul {
            @include flexContent;
            @include desktop {
                flex-direction: column;
                gap: .5rem;
                width: 100%;
            }
            @include mobile {
                flex-direction: row;
                justify-content: flex-start;
                gap: 1.25rem;
                width: 100%;
            }
            li {
                font-weight: 500;
                @include cursor;
                &.selected {
                    color: $primary;
                }
                @include desktop {
                    @include gridContent;
                    grid-template-columns: minmax(1.25rem, auto) 1fr;
                    column-gap: 1.25rem;
                    width: calc(100% - (1.25rem * 2));
                    padding: 1rem 1.25rem;
                    border: 1px solid $gray-0;
                    font-size: 1rem;
                    color: $white;
                    &.selected {
                        border-radius: .25rem;
                        border: 1px solid $primary;
                    }
                }
                
                @include mobile {
                    width: 100%;
                    padding: .5rem;
                    font-size: .875rem;
                    color: $gray-2;
                    white-space: nowrap;
                    i { @include hide;  }
                    &.selected {
                        border-bottom: 1px solid $primary;
                    }
                }
            }
        }
    }
    
    .footer {
        @include gridContent;
        grid-template-columns: 2.75rem 1fr auto;
        column-gap: 1rem;
        width: calc(100% - (1.25rem * 2));
        padding: 1.25rem;
        background: $gray-1;
        border-radius: .25rem;
        
        .imgArea {
            width: 2.75rem;
            height: 2.75rem;
        }
    
        div:not(.imgArea) {
            @include flexContent;
            flex-direction: column;
            align-items: flex-start;
            gap: .25rem;
            border: none !important;
            font-weight: 500;
    
            label {
                font-size: 1rem;
                color: $white;
                white-space:nowrap;
            }
    
            span {
                font-size: .875rem;
                color: $primary;
            }
        }
    
        small {
            font-size: 1.5rem;
            color: $white;
        }

        @include mobile { @include hide; }
    }
}
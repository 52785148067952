@import '../config/_colors';
@import '../config/variables';

.subFilter {
    width: 6.25rem;
    padding: 0.4064rem 0;
}

.itemContainer {
    @include flexContent;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    width: 100%;
    
    .itemArea {
        width: 100%;
        ul {
            @include flexContent;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            overflow-x: auto;
            li {
                @include flexContent;
                flex-direction: column;
                align-items: flex-start;
                cursor: pointer;
                .imgArea {
                    position: relative;
                    background-color: $white;
                    overflow: hidden;
                    @include radius;
                    small {
                        position: absolute;
                        top: .5rem;
                        right: .5rem;
                        font-weight: 400;
                        font-size: .75rem;
                    }
                }
                .itemInfo {
                    @include flexColumnContent;
                    align-items: flex-start;
                    width: 100% !important;
                    height: auto !important;
                    margin-top: .5rem;
                    label {
                        font-weight: 400;
                        font-size: .813rem;
                        color: $gray-3;
                        @include lineClamp2;
                    }
                    span {
                        font-weight: 600;
                        font-size: .75rem;
                        color: $primary-outline;
                    }
                }
            }

            @include desktop {
                width: 100%;
                gap: 1rem;

                li {
                    width: 8.25rem;

                    div {
                        width: 8.25rem;
                        height: 8.25rem;
                    }

                    label {
                        line-height: 1.25rem;
                        font-size: .875rem;
                    }

                    span {
                        line-height: 1.5rem;
                        font-size: 1rem;
                    }
                }
            }

            @include mobile {
                gap: .5rem;
                width: calc(100% - (1.25rem * 2));
                padding: 0 1.25rem;
                margin-top: .75rem;

                li {
                    width: 7.5rem;
                    gap: .25rem;

                    div {
                        width: 7.5rem;
                        height: 7.5rem;
                    }

                    label {
                        line-height: 1.125rem;
                        font-size: .75rem;
                    }

                    span {
                        line-height: 1.25rem;
                        font-size: .875rem;
                    }
                }
            }
        }

        @include mobile {
            &:last-child {
                margin-bottom: 1.25rem;
            }
        }
    }
}

/* bottomsheet */
.bottomsheetListArea {
    @include flexContent;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
    margin-bottom: 1.25rem;

    .brandListArea,
    .modelListArea {
        @include flexContent;
        flex-direction: row;
        gap: .5rem;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: .25rem;
        overflow-x: auto;
    }

    .brandListArea {
        li {
            padding: .25rem .75rem;
            border: 1px solid $gray-3;
            border-radius: 1rem;
            font-weight: 500; 
            font-size: .75rem;
            color: $gray-1;
            white-space: nowrap;
            @include cursor;

            &.selected {
                border: 1px solid $primary;
                background: $primary;
                color: $gray-1;
            }
        }
    }

    .modelListArea {
        li {
            div {
                @include flexContent;
                flex-direction: row;
                gap: .5rem;
                white-space: nowrap;

                label {
                    font-weight: 500;
                    font-size: .75rem;
                    color: $gray_1;
                }
            }
        }
    }
}

.column2 {
    @include gridContent;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    @include desktop { column-gap: 1.5rem; }
    @include mobile { column-gap: 1rem; }
}

.column3 {
    @include gridContent;
    grid-template-columns: repeat(3, 1fr);
    @include desktop { column-gap: 1.5rem; }
    @include mobile { column-gap: 1rem; }
}
/* START 수정리스트선택 */
.itemGroupContainer {
    width: 100%;
    ul {
        @include flexRowContent;
        justify-content: flex-start;
        align-items: flex-start;
        gap: .5rem;
        overflow: auto;
        width: 100%;
        li {
            button {
                height: 1.875rem;
                background-color: $gray-4;
                padding: 0 .5rem;
                border-radius: 1.875rem;
                font-weight: 400;
                font-size: .813rem;
                color: $gray-2;
                white-space: nowrap;
            }
        }
    }
}
/* END 수정리스트선택 */
/* bottomsheet-취급차량선택 */
.vehicleContainer {
    width: 100%;
    .titleArea {
        @include flexBetweenContent;
        width: 100%;
        color: $gray-0;
        @include desktop {
            font-weight: 600;
            h1 {
                font-size: 1.25rem;
            }   
        }
        @include mobile {
            font-weight: 500;
            h1 {
                font-size: 1rem;
            }   
        }
        span {
            font-size: 1rem;
            @include cursor;
        }
    }
    .searchArea {
        @include gridContent;
        grid-template-columns: 1fr auto;
        column-gap: 1.25rem;
        margin-top: .75rem;
        margin-bottom: .75rem;
        .searchword {
            @include gridContent;
            grid-template-columns: 1fr auto;
            background-color: $gray-4;
            border: 1px solid $gray-3;
            padding: .75rem 1.25rem; 
            @include radius;
            @include desktop {
                input[type=text] {
                    font-size: .875rem;
                }
            }
            @include mobile {
                input[type=text] {
                    font-size: .75rem;
                }
            }
            input[type=text] {
                background-color: transparent;
                border: none;
                font-weight: 400;
                color: $gray-1;
            }
            svg {
                font-size: 1rem;
                color: $gray-1;
            }
        }
        > div {
            @include flexRowContent;
            justify-content: flex-start;
            gap: .25rem;
            span {
                font-weight: 500;
                font-size: .75rem;
                color: $gray-1;
            }
        }
    }
    .brandListArea {
        @include flexColumnContent;
        gap: 1.25rem;
        justify-content: flex-start;
        max-height: 250px;
        overflow: auto;
        li {
            @include flexRowContent;
            align-items: flex-start;
            width: 100%;
            gap: 1rem;
            .imgArea {
                min-width: 3.25rem;
                width: 3.25rem;
                height: 3.25rem;
                background-color: $gray-4;
                border-radius: 50%;
                picture {
                    @include flexContent;
                }
                img {
                    width: 60% !important;
                    height: 60% !important;
                }
            }
            .modelListArea {
                @include flexRowContent;
                flex-wrap: wrap;
                justify-content: flex-start;
                row-gap: .25rem;
                width: 100%;
                min-height: 3.25rem;
                li {
                    @include flexRowContent;
                    justify-content: flex-start;
                    gap: .25rem;
                    @include desktop {
                        width: 50%;
                    }
                    @include mobile {
                        width: 50%;
                    }
                    span {
                        font-weight: 500;
                        font-size: .75rem;
                        color: $gray-1;
                        @include lineClamp;
                    }
                }
            }
        }
    }
}
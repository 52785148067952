@import '../config/_colors';
@import '../config/variables';

.userContainer {
    @include flexContent;
    width: 100%;

    @include desktop {
        background-image: url('../../images/bg/common-bg.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    @include mobile {
        background-image: '';
        background-color: $white;
    }
}

.wrapper {
    @include flexContent;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;

    @include desktop {
        width: calc(43.875rem - (5.38rem * 2));
        height: calc(47.5rem - (5.06rem * 2));
        padding: 5.06rem 5.38rem;
        border-radius: 1.25rem;
        background-color: $white;
    }
    @include mobile {
        width: calc(100% - (1.25rem * 2));
        height: calc(100% - (1.25rem * 2));
        padding: 1.25rem;
        border-radius: 0;
    }    
}

.Title {
    font-weight: 700;
    color: $gray-0;
    white-space: pre-line;

    @include desktop { line-height: 3.375rem; font-size: 2rem;}
    @include mobile { line-height: 2.25rem; font-size: 1.5rem;}
}

.subtitle {
    font-weight: 500;
    color: $gray-2;
    white-space: pre-line;

    @include desktop {
        margin-top: .5rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    @include mobile {
        margin-top: .75rem;
        font-size: .875rem;
        line-height: 1.25rem;
    }

    &.isMobile {
        @include desktop {
            display: none;
            border: 1px solid red;
        }
    }
}

/* 입력창 */
.inputArea {
    @include flexContent;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    @include desktop {margin-top: .75rem;}
    @include mobile {margin-top: 3.75rem;}

    .selectField,
    .inputField {
        @include flexContent;
        flex-direction: column;
        align-items: flex-start;
        gap: .75rem;
        width: 100%;

        label {
            font-weight: 500;
            color: $gray-1;
    
            @include desktop {font-size: .875rem;}
            @include mobile {font-size: .75rem;}
        }
    }

    .dateFormat {
        @include gridContent;
        grid-template-columns: 1fr auto 1fr;
        width: 100%;

        @include desktop {column-gap: .5rem;}
        @include mobile {column-gap: .25rem;}

        > div {
            border: 1px solid $gray-3;
            background: $white;
            color: $gray-1;

            @include desktop {
                width: calc(100% - 2px);
                padding: 1.25rem 0;
                border-radius: .25rem;
            }
            @include mobile {
                width: calc(100% - 2px);
                padding: .88rem 0;
                border-radius: .5rem;
            }
        }
    }

    .inputField {
        @include flexContent;
        flex-direction: column;
        align-items: flex-start;
        gap: .75rem;
        width: 100%;

        > div {
            position: relative;
            border: 1px solid $gray-3;
            background: $white;
            color: $gray-1;
    
            @include desktop {
                width: calc(100% - (1.5rem * 2) - 2px);
                padding: 1.25rem 1.5rem;
                border-radius: .25rem;
            }
            @include mobile {
                width: calc(100% - (1rem * 2) - 2px);
                padding: .88rem 1rem;
                border-radius: .5rem;
            }

            &:not(.select):has(input:placeholder-shown) {
                background: $gray-4;
                border: 1px solid $gray-4;
            }

            input {
                width: 100%;
                border: none;
                background: none;
                font-weight: 500;
                font-size: 1rem;
                color: $gray-1;
                ::placeholder {
                    font-weight: 400;
                    color: $gray-3;
                }   
            }
    
            label {
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                font-weight: 500;
                font-size: .75rem;
            }

            
        }
    }
}

/* 인증코드입력창 */
.verifyArea {
    @include gridContent;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1.38rem;

    > div {
        @include flexContent;
        padding: .75rem 0;
        border: 1px solid $gray-3;
        border-radius: .25rem;
        background: $white;

        &:has(input:placeholder-shown) {
            background: $gray-4;
            border: 1px solid $gray-4;
        }

        input {
            border: none;
            background: none;
            text-align: center;
            font-weight: 700;
            color: $gray-1;
            ::placeholder {
                font-weight: 400;
                color: $gray-3;
            }   
            
            @include desktop {
                width: 1.375rem;
                height: 3.375rem;
                font-size: 2rem;
            }

            @include mobile {
                width: 1rem;
                height: 2.25rem;
                font-size: 1.5rem;
            }
        }
    }
}

/* 이미지출력 */
.mobileImgArea {
    height: 100%;
    @include desktop {display: none;}
    @include mobile {
        @include flexContent;

        img {
            width: 14.1875rem;
        }
    }
}
.imgArea {
    @include flexContent;
    height: 100%;

    img {
        width: 17.9375rem;
    }
}

//파일버튼
.attachFileArea {
    @include flexContent;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    > div { width: 100%; }
}

/* 주소검색팝업 */
.postcodeArea {
    position: relative;
    @include gridContent;
    grid-template-columns: 1rem 1fr;
    column-gap: 1.25rem;

    b {
        font-size: 1rem;
        color: $primary-outline;
    }

    .postcodePopup {
        position: absolute;
        left: 0;
        top: calc(100% + .5rem);
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $gray-3;
        overflow: auto;
        z-index: 4;
        @include desktop {
            height: 150px;
            border-radius: .25rem;
        }
        @include mobile {
            height: 300px;
            border-radius: .5rem;
        }
    }
}

/* 단계 */
.stepArea {
    @include desktop {
        background: $white;
        border-radius: .5rem;
        padding: .75rem 1.5rem;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .1); 
    }
    
    > div {
        @include desktop {
            display: none;
        }

        @include mobile {
            @include flexContent;
            position: fixed;
            top: 1.25rem;
            right: 1.25rem;
            height: 1.5rem;
        }

        span {
            font-weight: 600;
            font-size: .875rem;
            color: $primary-outline;
        }
    }
    
    ul {
        @include desktop {
            @include flexContent;
            flex-direction: row;
            gap: 1.25rem;
    
            li {
                font-weight: 500;
                font-size: .75rem;
                color: $gray-25;
                
                &.selected {
                    color: $primary-outline;
                }
    
                &.arrow {
                    font-size: 1rem;
                }
            }
        }

        @include mobile {
            display: none;
        }
    }
}

.loginBtnArea {
    @include flexContent;
    flex-direction: column;
    @include desktop {gap: 3rem;}
    @include mobile {gap: 1rem;}

    > .signupBtn {
        @include desktop {display: none;}
    }

    .navBtnArea {
        @include desktop {
            @include gridContent;
            grid-template-columns: 1fr auto;
            width: 100%;
        }
        @include mobile {
            margin-top: .5rem;
        }

        > div {
            @include flexContent;
            @include desktop {justify-content: flex-start;}
            @include mobile {justify-content: center;}

            a,
            button {
                @include mobile {font-size: .875rem;}

                &:first-child {
                    padding-right: .5rem;
                    border-right: 1px solid $gray-3;        
                }
                &:nth-child(2) {
                    padding-left: .5rem;
                }
            }
        }

        a,
        button {
            font-weight: 500;
            font-size: 1rem;
            color: $gray-2;
        }

        > .signupBtn {
            @include mobile {display: none;}
        }
    }
}
.btnArea {
    @include flexContent;
    flex-direction: column;
    @include desktop {gap: 1.25rem;}
    @include mobile {gap: 1rem;}
}

.boderNoneBtn {
    border: none !important;
    color: $gray-2 !important;
}


.termListArea {
    @include flexContent;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.25rem;
    width: 100%;
}

.ChkAll {
    @include gridContent;
    grid-template-columns: auto 1fr ;
    column-gap: .75rem;
    width: calc(100% - (.88rem * 2));
    background: $gray-4;
    border-radius: 8px;
    padding: .88rem;
    margin-bottom: .25rem;
    
    label {
        font-weight: 500;
        
        color: $gray-1;

        @include desktop {
            font-size: 1.125rem;
        }
        @include mobile {
            font-size: 1rem;
        }
    }
}

.checkBoxArea {
    @include flexContent;
    flex-direction: column;
    gap: .5rem;
    width: 100%;

    .checkbox {
        @include gridContent;
        grid-template-columns: auto 1fr auto;
        column-gap: .75rem;
        width: calc(100% - (.88rem * 2));
        padding: 0 .88rem;

        > * {
            min-width: 0;
        }

        label {
            font-weight: 500;
            color: $gray-1;

            @include desktop {
                font-size: .875rem;
            }
            @include mobile {
                font-size: .75rem;
            }
        }

        small {
            min-width: 0;
            font-weight: 500;
            color: $primary_outline;
            text-decoration: underline;
            cursor: pointer;

            @include desktop {
                font-size: .875rem;
            }
            @include mobile {
                font-size: .75rem;
            }
        }
    }

    &.checkBoxOnly {
        .checkbox {
            @include gridContent;
            grid-template-columns: auto 1fr auto;
            column-gap: .75rem;
            width: 100% !important;
            padding: 0 !important;
        }
    }

    pre {
        padding: 1rem;
        border: 1px solid $gray-4;
        border-radius: 8px;
        line-height: 1rem;
        font-weight: 400;
        font-size: .6875rem;
        color: $gray-2;
        white-space: break-spaces;
        
        * {
            font-size: .6875rem;
        }
    }
}

.checkbox {
    @include flexContent;
    label {
        @include flexContent;
        justify-content: flex-start;
        gap: .88rem;
        width: 100%;
        span {
            @include checkboxLabel;
            div {
                @include checkbox;
                div {
                    @include checkboxChecked;
                }
            }
        }
    }
}
@import '../../config/_colors.scss';
@import '../../config/_variables.scss';

#parentDiv-home div[data-rsbs-overlay] {
    border-top-left-radius: 16px;
    display: flex;
    background: $white;
    flex-direction: column;
    height: 0;
    height: var(--rsbs-overlay-h, 0px);
    will-change: height;
    --rsbs-content-opacity: 1 !important;
    --rsbs-backdrop-opacity: 1 !important;
    opacity: 1 !important;
}

:root {
    --rsbs-backdrop-bg: rgba(0, 0, 0, .7);
    --rsbs-bg: #FFF;
    --rsbs-handle-bg: #D9D9D9;
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 1.25rem;    

    --rsbs-content-opacity: 1 !important;
    --rsbs-backdrop-opacity: 1!important;
    --rsbs-antigap-scale-y: 0!important;
    --rsbs-overlay-translate-y: 0px!important;
    --rsbs-overlay-rounded: 0px!important;
    --rsbs-overlay-h: 851px!important;
}

reach-portal {
    position: relative;
    z-index: 4;
    > * {
        opacity: 1 !important;
    }
}

[data-rsbs-overlay] {
    @include flexContent;
    flex-direction: column;
    z-index: 4;
    height: 100%;
    @include desktop {
        gap: 1.25rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*
        width: calc(38.875rem - (5.38rem * 2));
        max-height: calc(48.6875rem - (3.78rem * 2));
        */
        width: 38.875rem;
        max-height: 48.6875rem;
        padding: 2.5rem;
        border-radius: 1.25rem !important;
        box-sizing: border-box;
    }

    @include mobile {
        gap: 2.5rem;
        border-radius: 0;
        border-top-left-radius: 1.25rem !important;
        border-top-right-radius: 1.25rem !important;
    }

    [data-rsbs-header],
    [data-rsbs-content],
    [data-rsbs-footer] {
        box-shadow: none;
        @include desktop {
            width: 100%;
        }
        @include mobile {
            @include wrap;
        }
    }

    /* header */    
    [data-rsbs-header] {
        position: realtive;
    
        .btnBottomSheetClose {
            @include desktop {
                position: absolute;
                top: -2.5rem;
                right: 0;
                font-size: 1.5rem;
                color: $white;
            }
            
            @include mobile {
                @include hide;
            }
        }

        .TItleContainer {
            @include flexContent;
            flex-direction: column;
            gap: .5rem;

            @include mobile {
                padding: calc(.38rem + .25rem + 1.25rem) 0 .75rem;
                border-bottom: .0625rem solid $gray-4;
            }
        }
    
        .TitleArea {
            @include gridContent;
            grid-template-columns: 4.2rem 1fr 4.2rem;
            column-gap: 2.6rem;

            &.SubtitleArea {
                @include flexContent;
                flex-direction: column;
                @include desktop {
                    gap: .5rem;
                }
                @include mobile {
                    gap: .75rem;
                }
                
            }

            @include mobile {
                padding: calc(.38rem + .25rem + 1.25rem) 0 .75rem;
                border-bottom: .0625rem solid $gray-4;
            }
    
            .btnPrev {
                @include flexContent;
                justify-content: flex-start;
    
                button {
                    font-size: 1.5rem;
                    color: $gray-0;
                }
            }

            .btnRemove {
                text-align: right;
                button {
                    font-weight: 500;
                    color: $primary-outline;
                    @include desktop {
                        font-size: .875rem;
                    }
                    @include mobile {
                        font-size: .75rem;
                    }
                }
            }
    
            b {
                padding: .38rem .5rem;
                background-color: $primary-bg;
                @include radius;
                font-weight: 700;
                font-size: .625rem;
                color: $primary-outline;
                @include lineClamp;
            }
        }

        .Title {
            text-align: center;
            color: $gray-0;
            @include desktop {
                font-weight: 700;
                font-size: 1.5rem;
            }
            @include mobile {
                font-weight: 600;
                font-size: 1.125rem;
            }
        }
        .subTitle {
            font-weight: 500;
            @include desktop {
                margin-top: .5rem;
                
                font-size: 1rem;
                color: $gray-6;
            }
            @include mobile {
                margin-top: .75rem;
                text-align: center;
                font-size: .875rem;
                color: $gray-2;
            }
        }
    
        .SearchFilter {
            padding: calc(.38rem + .25rem + 1.25rem) 0 .75rem;
        }
    
        @include desktop {
            &::before {
                display: none;
            }
        }
        @include mobile {
            &::before {
                top: .38rem;
                width: 2.5rem; 
                height: .25rem;
                border-radius: .125rem; 
            }
        }
    }

    /* content */    
    [data-rsbs-scroll] {
        width: 100%;

        [data-rsbs-content] {
            .contentArea {
                @include mobile {
                    margin-top: 0;
                    margin-bottom: 2.5rem;
                }
            }

            .titleArea {
                > div {
                    @include desktop {
                        text-align: left;
                    }
                    @include mobile {
                        text-align: center;
                    }
                }

                .Title {
                    
                    color: $gray-0;
                    @include desktop {
                        font-weight: 700;
                        font-size: 1.5rem;
                    }
                    @include mobile {
                        font-weight: 600;
                        font-size: 1.125rem;
                    }
                }
                .subTitle {
                    font-weight: 500;
                    @include desktop {
                        margin-top: .5rem;
                        
                        font-size: 1rem;
                        color: $gray-6;
                    }
                    @include mobile {
                        margin-top: .75rem;
                        font-size: .875rem;
                        color: $gray-2;
                    }
                }
            }
        }
    }
    
    /* footer */
    [data-rsbs-footer] {
        .btnArea {
            @include flexContent;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 1.25rem;
            width: 100%;
    
            .submitButton {
                @include submitButton;
                @include submitCurrButton;
                &:disabled {
                    @include buttonDisabled;
                }
            }
    
            .secButton {
                @include submitButton;
                @include submitSecButton;
                &:disabled {
                    @include buttonDisabled;
                }
            }

            .errorButton {
                @include submitButton;
                background: $error;
                border: 1px solid $error;
                color: $white;
                &:disabled {
                    @include buttonDisabled;
                }
            }
        }
    }

}

/* 닫기x */
[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
    [data-rsbs-header],
    [data-rsbs-content],
    [data-rsbs-footer] {
        box-shadow: none;
        width: 100%;
        max-width: 100%;
        padding: 0;
    }

    bottom: 4.165rem;
    box-shadow: none;
    z-index: 2;
}

/* 백그라운드x */
.posCenter {
    @include desktop {
        [data-rsbs-scroll] {
            flex-grow: 1;
        }
    }
    @include mobile {
        [data-rsbs-overlay] {
            bottom: 50%;
            transform: translateY(50%);
            @include flexContent;
            background: transparent;

            [data-rsbs-header] {
                &::before {
                    display: none;
                }
            }

            [data-rsbs-scroll] {
                flex-grow: 0;
            }

            .TitleArea {
                border-bottom: none;
            }

            .Title {
                border-bottom: none;
                color: $white;
            }
        }
    }
}

/* content */
.desktop_left {
    width: 100%;
    @include desktop {
        text-align: left !important;
    }
}

.mobileIconArea {
    @include desktop { @include hide; }
    @include mobile {
        @include flexContent;
        width: 100%;
        margin-top: 1.25rem;
        margin-bottom: 2.5rem;
        
        > div {
            @include flexContent;
            width: 7.5rem;
            height: 7.5rem;
        }
    }
}

[data-rsbs-scroll] {
    [data-rsbs-content] {
        .contentArea {
            @include flexContent;
            flex-direction: column;
            gap: 1.25rem;
            margin-top: 1.25rem;
            margin-bottom: 2.5rem;

            &.TextField {
                @include flexContent;
                flex-direction: column;
    
                .selectField {
                    @include flexContent;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: .38rem;
                    width: 100%;
                    @include desktop {
                        font-size: 1rem;
                    }
                    @include mobile {
                        font-size: .75rem;
                    }
                    > label {
                        font-weight: 500;
                        color: $gray-1;
                        @include desktop {
                            font-size: .875rem;
                        }
                        @include mobile {
                            font-size: .75rem;
                        }
                    }
                }

                .inputField,
                .radioField {
                    @include flexContent;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: .38rem;
                    width: 100%;
                    @include desktop { font-size: .875rem; }
                    @include mobile { font-size: .75rem; }

                    > label {
                        @include gridContent;
                        grid-template-columns: 1fr auto;
                        width: 100%;
                        font-weight: 500;
                        color: $gray-1;
                        @include desktop { font-size: .875rem; }
                        @include mobile { font-size: .75rem; }

                        b {
                            text-align: right;
                            font-weight: 400;
                            font-size: .75rem;
                            color: $primary-outline;
                        }
                    }
    
                    > div:not(.column),
                    .column > div {
                        @include gridContent;
                        grid-template-columns: 1fr auto;
                        box-sizing: border-box;
                        width: 100%;
                        border: 1px solid $gray-3;
                        background: $gray-4;
                        font-weight: 400;
                        font-size: inherit;
                        @include radius;
                        @include desktop { padding: 1rem 1.25rem; }
                        @include mobile { padding: .88rem 1rem; }
                        > * {
                            background: none;
                            border: none;
                            font-weight: inherit;
                            font-size: inherit;
                            color: $gray-0;
                        }

                        textarea {
                            overflow-y: auto;

                            @include desktop { 
                                height: calc(9.94925rem - (.88rem * 2));
                                line-height: 1.5rem; 
                            }
                            @include mobile { 
                                height: calc(6.5rem - (1rem * 2));
                                line-height: 1.125rem; 
                            }

                            &::placeholder {
                                font-weight: 400;
                                font-size: inherit;
                            }
                        }

                        &:has(input:not(:placeholder-shown)),
                        &:has(textarea:not(:placeholder-shown)) {
                            border: 1px solid #C9C9C9;
                            background: $white !important;
                            color: $gray-1;
                        }

                        &:has(textarea:placeholder-shown) {
                            textarea {
                                @include desktop { 
                                    line-height: calc(9.94925rem - (.88rem * 2));
                                }
                                @include mobile { 
                                    line-height: calc(6.5rem - (1rem * 2));
                                }
                                text-align: center;
                            }
                        }
                    }

                    > em {
                        width: 100%;
                        text-align: right;
                        font-weight: 400;
                        color: $gray-25;
                        @include desktop { font-size: .75rem; }
                        @include mobile { font-size: .6875rem; }
                    }
                }

                .radioField {
                    .column {
                        @include flexContent;
                        flex-direction: row;
                        width: 100%;

                        > div {
                            @include flexContent;
                            flex-direction: row;
                            justify-content: flex-start;
                            gap: .5rem;
                            padding: 0 !important;
                            border: none !important;
                            background: none !important;
                        }
                    }
                }
            }
        }
    }
}

/* 컨텐츠높이 100% */
.fullHeightContent {
    [data-rsbs-scroll] {
        @include mobile {
            @include flexContent;
            height: 100%;
        }
    }
}